<template>
<div>
<div class='progressR' :style="bg(value)">
	
	<slot/>
</div>
</div>
</template>
<script>

export default {
  props: {
  	value: { type: Number, default: 0.000 },
    max: { type: Number, default: 0.000 },
  	color: { type: String, default: 'rgba(80,80,220,.5)'}
  },
 components : {
    
  },
  data () {
    return {
      
    }
  },
  methods: {
	  bg( value )
	  {
		  let p1 = Math.round(value/this.max * 100);
		  return "background: linear-gradient(to right, "+this.color+" "+p1+"%, transparent "+(p1)+"%)";
	  }
  }
 }
</script>
<style lang="scss" scoped>
.progressR {
    width: 40px;
    color: #000;
    height: 22px;
    position: relative;
    font-size: 8pt;
    text-align: right;
}
.progressXx:after {
    content: '\A';
    position: absolute;
    opacity: 0.5;
    background-color: #2c2;
    top: 0; bottom: 0;
    left: 0; 
    width: 50%; 
}

</style>